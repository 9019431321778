body {
  font-family: 'Overpass Mono', 'Helvetica Neue', helvetica, arial, sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}

a,
a:visited {
  color: #00e;
}

#wrapper {
  display: grid;
  grid-template-areas:
    'converter support'
    'converter sponsor';
  grid-template-columns: 70vw 30vw;
  grid-template-rows: max-content min-content auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (max-width: 700px) {
  #wrapper {
    grid-template-areas: 'support' 'converter' 'sponsor';
    grid-template-columns: none;
    grid-template-rows: min-content 100vh;
    min-height: 100vh;
  }
}

.converter-container {
  grid-area: converter;
  display: grid;
  grid-template-rows: 1fr 77px 1fr;
  grid-template-areas: 'up' 'middle' 'down';
  position: relative;
  height: 90vh;
}

@media (display-mode: standalone) {
  .converter-container {
    height: 100vh;
  }
}

.support-container {
  grid-area: support;
  background: #bbb;
  padding: 15px;
}

.support-container h1 {
  line-height: 1;
}

.sponsor-container {
  grid-area: sponsor;
  background: #bbb;
}

.input-container {
  grid-area: up;
}

.options-container {
  grid-area: middle;
}

.output-container {
  grid-area: down;
  overflow: hidden;
}

.container-utils {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

#copy-bug {
  -webkit-appearance: none;
  font-size: 16px;
  padding: 10px 20px;
  border: 1px solid #ddd;
  font-family: inherit;
  background: rgba(255, 255, 255, 0.9);
}

@keyframes bugout {
  from {
    background-color: hsla(14, 100%, 80%, 1);
  }
  to {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

#copy-bug.just-added {
  animation-name: bugout;
  animation-duration: 0.6s;
  /* background: red; */
}

.input-container,
.output-container {
  background: #eee;
  display: flex;
  position: relative;
}

.failed-convert-note {
  position: absolute;
  left: 15px;
  top: 15px;
  background: red;
}

.drop-info {
  font-size: 90px;
  position: fixed;
  top: 50px;
  right: 50px;
  bottom: 50px;
  left: 50px;
  background: red;
}
body.droppable .drop-info {
  display: block;
}

textarea {
  resize: none;
  font-family: inherit;
}

textarea:focus {
  outline: none;
}

textarea,
#output {
  padding: 40px;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  font-size: 20px;
  overflow-wrap: break-word;
  word-break: break-all;
  overflow-y: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (max-width: 700px) {
  /* TODO: where is this getting width from? */
  #more-info {
    width: 100vw;
  }
}
.options-container {
  display: flex;
  padding: 10px 5px;
  justify-content: center;
}

.options-container label {
  padding: 15px;
  position: relative;
  border: 1px solid #eee;
  margin: 0 10px;
  border-radius: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  line-height: 16px;
}

.options-container label span {
  flex-grow: 1;
  padding-left: 5px;
  font-size: 12px;
}

/* scale from 12px to 16px from 375px to 400px */
@media (min-width: 375px) {
  .options-container label span {
    font-size: calc(0.75rem + ((1vw - 3.75px) * 16));
  }
}
/* Prevent font scaling beyond this breakpoint */
@media (min-width: 400px) {
  .options-container label span {
    font-size: 16px;
  }
}

.about-container {
  grid-area: about;
  position: relative;
  padding-bottom: 200px;
}

.about-container-info {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
}

.about-container-info-nav {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 14px;
  max-width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.about-container-info-nav li {
  list-style: none;
  word-wrap: none;
  white-space: nowrap;
}
.about-container-info-nav li a {
  padding: 12px;
  display: inline-block;
}
.about-container-info-nav li a:hover {
  background: #ebebeb;
}

.about-container-contents {
  padding: 0 30px 30px;
  max-width: 900px;
  margin: 0 auto;
  overflow: hidden;
  line-height: 2;
}
.about-container-contents h2 {
  padding-top: 40px;
}

.about-container-contents .table-container {
  width: 100vw;
  margin-left: -30px;
  padding-left: 30px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.about-container-contents .code,
.about-container-contents code {
  font-family: 'Overpass Mono', 'Courier New', monospace;
  background: #eee;
  padding: 4px;
  border-radius: 4px;
}

@import './base64-tables.css';
