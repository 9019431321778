.ascii-reference-table {
  line-height: 12px;
  border-collapse: collapse;
  caption-side: bottom;
}
.ascii-reference-table th {
  background: #eee;
  border-collapse: collapse;
}
.ascii-reference-table td,
.ascii-reference-table th {
  padding: 10px;
  text-align: center;
}

.ascii-reference-table .index {
  font-size: 10px;
}
.ascii-reference-table span {
  display: block;
}

/* ---------- */

.base64-reference-table {
  text-align: center;
  border-collapse: collapse;
  caption-side: bottom;
}
.base64-reference-table td,
.base64-reference-table th {
  min-width: 70px;
}
.base64-reference-table .table-gap {
  min-width: 20px;
}
.base64-reference-table th,
.base64-reference-table td {
  padding: 5px;
}
.base64-reference-table th {
  background: #ccc;
}
.base64-reference-table td:nth-of-type(3n-1)::after,
.base64-reference-table td:nth-of-type(3n-1)::before {
  content: '"';
}
.base64-reference-table th.table-gap {
  background: transparent;
}

.base64-reference-table td:nth-of-type(3n-2) {
  background: #eee;
}

/* ---------------------- */

pre.conversion-between-bases-table {
  font-family: 'Overpass Mono', 'Courier New', monospace;
  font-weight: bold;
  margin: 0;
}
